import { BaseJWTService } from "@/services/base";

export class AlfredService extends BaseJWTService {
  constructor({ apiUrl }) {
    super({ apiUrl });
  }

  async login(payload) {
    const response = await this.api.post("/accounts/login", payload);
    return response.data;
  }

  async refreshToken(payload) {
    const response = await this.api.post("/accounts/refresh", payload);
    return response.data;
  }

  async getUser() {
    const response = await this.api.get("/accounts/user");
    return response.data;
  }

  async updateUser(payload) {
    const response = await this.api.patch("/accounts/user", payload);
    return response.data;
  }

  async getUsers(urlParams) {
    let url = "/accounts/users";
    if (urlParams) {
      const searchParams = new URLSearchParams(urlParams);
      url = `${url}?${searchParams.toString()}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async adminGetUser(id) {
    const response = await this.api.get(`/accounts/users/${id}`);
    return response.data;
  }

  async addUserCreditsBalance(payload) {
    const response = await this.api.patch(
      "/admin/add-user-credits-balance",
      payload
    );
    return response.data;
  }
  async updateSubUserCredits(payload){
    const response = await this.api.patch(
      "/accounts/subuser-credits",
      payload
    );
    return response.data;
  }
  async partnerSettingsCreate(payload){
    const response = await this.api.post(
      "/admin/partner-setting",
      payload
    );
    return response.data;
  }

  async partnerSettingsGet(user){
    const response = await this.api.get(
      `/admin/partner-setting/partner?user=${user}`
    );
    return response.data;
  }

  async partnerSettingsDelete(user){
    const response = await this.api.delete(
      `/admin/partner-setting?user=${user}`
    );
    return response.data;
  }

  async partnerSettingsRegGet(partner_code){
    const response = await this.api.get(
      `/admin/partner-setting?partner_code=${partner_code}`
    );
    return response.data;
  }

  async add_user(payload) {
    const response = await this.api.post("/accounts/user-create", payload);
    return response.data;
  }

  async get_user(payload) {
    const response = await this.api.get("/accounts/user-create", payload);
    return response.data;
  }
  async reactivate_user(payload) {
    const response = await this.api.patch("/accounts/user-create", {email: payload});
    return response.data;
  }

  async delete_user(email) {
    const response = await this.api.delete(`/accounts/user-create?email=${email}`);
    return response.data;
  }

  async register(payload) {
    const response = await this.api.post("/accounts/create", payload);
    return response.data;
  }

  async verifyEmail(payload) {
    const response = await this.api.post("/accounts/verify-email", payload);
    return response.data;
  }

  async resetPassword(payload) {
    const response = await this.api.post(
      "/accounts/reset-password/initiate",
      payload
    );
    return response.data;
  }

  async confirmResetPassword(payload) {
    const response = await this.api.post(
      "/accounts/reset-password/verify",
      payload
    );
    return response.data;
  }

  async uploadFile(payload) {
    const response = await this.api.post("/upload", payload);
    return response.data;
  }

  async realtimeEmailCheck(payload) {
    const response = await this.api.post("/email-check", payload);
    return response.data;
  }

  async realtimeEmailDashboard(urlParams) {
    let url = "/email-check/dashboard";
    if (urlParams) {
      const searchParams = new URLSearchParams(urlParams);
      url = `${url}?${searchParams.toString()}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async realtimeEmailSummary(id) {
    let url = `/email-check/${id}/summary_combined`;   
    const response = await this.api.get(url);
    return response.data;
  }

  async realtimeEmailPreview(id,urlParams) {
    let url = `/email-check/${id}/preview`;   
    if (urlParams) {
      const searchParams = new URLSearchParams(urlParams);
      url = `${url}?${searchParams.toString()}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async UserStats(payload) {
    let url = "/admin/statistics";
    const response = await this.api.post(url, payload);
    return response.data;
  }

  async UserStatsTopUser(payload) {
    let url = "/admin/statistics/top-users";
    const response = await this.api.post(url, payload);
    return response.data;
  }

  async StatsNewUsers(urlParams) {
    let url = "/admin/user-activity";
    if (urlParams) {
      const searchParams = new URLSearchParams(urlParams);
      url = `${url}?${searchParams.toString()}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async StatsCreditPurchase(urlParams) {
    let url = "/admin/credit-purchase";
    if (urlParams) {
      const searchParams = new URLSearchParams(urlParams);
      url = `${url}?${searchParams.toString()}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async StatsCreditChurnUsers(urlParams) {
    let url = "/admin/churn-user";
    if (urlParams) {
      const searchParams = new URLSearchParams(urlParams);
      url = `${url}?${searchParams.toString()}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async StatsChurnUsersActivity(urlParams) {
    let url = "/admin/churn-user/activity";
    if (urlParams) {
      const searchParams = new URLSearchParams(urlParams);
      url = `${url}?${searchParams.toString()}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async Bouncer(urlParams) {
    let url = "/admin/bouncer";
    if (urlParams) {
      const searchParams = new URLSearchParams(urlParams);
      url = `${url}?${searchParams.toString()}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async fetchAdminJobs(urlParams) {
    let url = "/admin/jobs";

    if (urlParams) {
      if (urlParams.emailJob && urlParams.emailJob == "Real-time") {
        url = "/admin/email-check";
      }
      const searchParams = new URLSearchParams(urlParams);
      url = `${url}?${searchParams.toString()}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async fetchDashboardJobs(urlParams) {
    let url = "/jobs/dashboard";
    if (urlParams) {
      const searchParams = new URLSearchParams(urlParams);
      url = `${url}?${searchParams.toString()}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async fetchDashboard(urlParams) {
    let url = "/dashboard";
    if (urlParams) {
      const searchParams = new URLSearchParams(urlParams);
      url = `${url}?${searchParams.toString()}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async fetchJobs(urlParams) {
    let url = "/jobs";
    if (urlParams) {
      if (urlParams.emailJob && urlParams.emailJob == "Real-time") {
        url = "/email-check";
      }
      const searchParams = new URLSearchParams(urlParams);
      url = `${url}?${searchParams.toString()}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }


  async getJob(id) {
    const response = await this.api.get(`/jobs/${id}`);
    return response.data;
  }

  async createJob(payload) {
    const response = await this.api.post("/jobs", payload);
    return response.data;
  }

  async deleteJob(id) {
    const response = await this.api.delete(`/jobs/${id}`);
    return response.data;
  }

  async deleteUpload(id) {
    const response = await this.api.delete(`/upload/${id}`);
    return response.data;
  }

  async verifyJob(payload) {
    const response = await this.api.post("/jobs/verify", payload);
    return response.data;
  }

  shouldRefresh(config) {
    if (config.url === "/accounts/refresh") {
      return false;
    }
    return BaseJWTService.prototype.shouldRefresh.call(this, config);
  }

  async createPaymentMethod(payload) {
    const response = await this.api.post("/accounts/payment-methods", payload);
    return response.data;
  }

  async expertDownload(id, payload) {
    const response = await this.api.post(
      `/jobs/${id}/expert-download`,
      payload
    );
    return response.data;
  }

  async threatFileDownload(id, searchParams) {
    const response = await this.api.get(
      `/jobs/${id}/threat-file-download?${searchParams}`      
    );
    return response.data;
  }

  async klaviyoExport(payload) {
    const response = await this.api.post("/klaviyo-export", payload);
    return response.data;
  }

  async klaviyoAPIKey() {
    const response = await this.api.get("/klaviyo-api-key");
    return response.data;
  }

  async klaviyoAPIKeyDelete() {
    const response = await this.api.delete("/klaviyo-api-key");
    return response.status;
  }

  async postKlaviyoAPIKey(payload) {
    try {
      return await this.api.post("/klaviyo-api-key", payload);
    } catch (error) {
      return error.status;
    }
  }

  async grExport(payload) {
    const response = await this.api.post("/getresponse-export", payload);
    return response.data;
  }

  async postgrAPIKey(payload) {
    try {
      return await this.api.post("/getresponse-api-key", payload);
    } catch (error) {
      return error.status;
    }
  }

  async grAPIKey() {
    const response = await this.api.get("/getresponse-api-key");
    return response.data;
  }

  async grAPIKeyDelete() {
    const response = await this.api.delete("/getresponse-api-key");
    return response.status;
  }

  async yotpoGetAccessToken() {
    const response = await this.api.get("/yotpo-access-key");
    return response.data;
  }

  async yotpoGenerateAccessToken(payload) {
    const response = await this.api.post("/yotpo-access-key", payload);
    return response.data;
  }

  async yotpoGetLists(urlParams) {
    const searchParams = new URLSearchParams(urlParams);
    const response = await this.api.get(`/yotpo-lists?${searchParams.toString()}`);
    return response.data;
  }

  async yotpoGetListsMembers(payload) {
    let url = `/yotpo-lists/${payload.id}`;
    if (payload.page_info) {
      url = url + `?next_page_info=${payload.page_info}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async yotpoImport(payload) {
    const response = await this.api.post("/yotpo-import", payload);
    return response.data;
  }

  async yotpoExport(payload) {
    const response = await this.api.post("/yotpo-export", payload);
    return response.data;
  }

  async yotpoExportDetails(payload) {
    const response = await this.api.get(`/yotpo-export?job_id=${payload}`);
    return response.data;
  }

  async yotpoRemoveAccessToken() {
    const response = await this.api.delete("/yotpo-access-key");
    return response.data;
  }

  async postIterableAPIKey(payload) {
    try {
      return await this.api.post("/iterable-api-key", payload);
    } catch (error) {
      return error.status;
    }
  }

  async getIterableAPIKey() {
    const response = await this.api.get("/iterable-api-key");
    return response.data;
  }

  async getIterableList() {
    const response = await this.api.get("/iterable-lists");
    return response.data;
  }

  async getIterableListSize(list_id) {
    const response = await this.api.get(`/iterable-lists/size?list_id=${list_id}`);
    return response.data;
  }

  async iterableImport(payload) {
    const response = await this.api.post("/iterable-import", payload);
    return response.data;
  }

  async iterableAPIKeyDelete() {
    const response = await this.api.delete("/iterable-api-key");
    return response.status;
  }

  async iterableExport(payload) {
    const response = await this.api.post("/iterable-export", payload);
    return response.data;
  }

  async iterableExportDetails(payload) {
    const response = await this.api.get(`/iterable-export?job_id=${payload}`);
    return response.data;
  }

  async fetchPaymentMethods() {
    const response = await this.api.get("/accounts/payment-methods");
    return response.data;
  }

  async deletePaymentMethod(id) {
    const response = await this.api.delete(`/accounts/payment-methods/${id}`);
    return response.data;
  }

  async patchPaymentMethod(id, payload) {
    const response = await this.api.patch(
      `/accounts/payment-methods/${id}`,
      payload
    );
    return response.data;
  }

  async verifyCreditPack(payload) {
    const response = await this.api.post("/credit-packs/verify", payload);
    return response.data;
  }

  async createPayment(payload) {
    const response = await this.api.post("/credit-packs", payload);
    return response.data;
  }

  async confirmPayment(payload, creditpackId) {
    const response = await this.api.patch(
      `/credit-packs/${creditpackId}`,
      payload
    );
    return response.data;
  }

  async createCreditPack(payload) {
    const response = await this.api.post("/credit-packs", payload);
    return response.data;
  }

  async fetchCreditPacks() {
    const response = await this.api.get("/credit-packs");
    return response.data;
  }

  async fetchTransactions(urlParams = {}) {
    let url = "/transactions";
    if (urlParams) {
      const searchParams = new URLSearchParams(urlParams);
      url = `${url}?${searchParams.toString()}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async updateBillingAddress(payload) {
    let url = "/transactions/billing-address";
    const response = await this.api.post(url, payload)
    return response.data;
  }

  async getBillingAddress() {
    let url = "/transactions/get-billing-address";
    const response = await this.api.get(url)
    return response.data;
  }

  async deleteTax(urlParams) {
    const response = await this.api.delete(
      `/transactions/delete-tax?tax_id=${urlParams}`
    );
    return response.data;
  }

  async getPricing(urlParams = {}) {
    let url = "/credit-packs/pricing-table";
    if (urlParams) {
      const searchParams = new URLSearchParams(urlParams);
      url = `${url}?${searchParams.toString()}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async changePassword(payload) {
    const response = await this.api.post("/accounts/change-password", payload);
    return response.data;
  }

  async resetSubUserPassword(payload) {
    const response = await this.api.post("/accounts/reset-subuser-password", payload);
    return response.data;
  }

  async changeEmail(payload) {
    const response = await this.api.post(
      "/accounts/change-email/initiate",
      payload
    );
    return response.data;
  }

  async confirmChangeEmail(payload) {
    const response = await this.api.post(
      "/accounts/change-email/verify",
      payload
    );
    return response.data;
  }

  async getSetting(id, urlParams = {}) {
    let url = `/admin/settings/${id}`;
    if (urlParams) {
      const searchParams = new URLSearchParams(urlParams);
      url = `${url}?${searchParams.toString()}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async changeSetting(payload) {
    const response = await this.api.post("/admin/settings", payload);
    return response.data;
  }
  async usageSummary(payload) {
    const response = await this.api.post("/admin/usage-summary", payload);
    return response.data;
  }
  async fetchPublicSettings() {
    const response = await this.api.get(`/admin/settings/public`);
    return response.data;
  }

  async getJobPreview(id, urlParams) {
    let url = `/jobs/${id}/preview`;
    if (urlParams) {
      const searchParams = new URLSearchParams(urlParams);
      url = `${url}?${searchParams.toString()}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async fetchDiscountCodes(urlParams) {
    let url = "/admin/discount-codes";
    if (urlParams) {
      const searchParams = new URLSearchParams(urlParams);
      url = `${url}?${searchParams.toString()}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async createDiscountCode(payload) {
    const response = await this.api.post("/admin/discount-codes", payload);
    return response.data;
  }

  async changeDiscountCode(id, payload) {
    const response = await this.api.put(`/admin/discount-codes/${id}`, payload);
    return response.data;
  }

  async deleteDiscountCode(id, payload) {
    const response = await this.api.delete(
      `/admin/discount-codes/${id}`,
      payload
    );
    return response.data;
  }

  async checkDiscountCode(discountCode) {
    const response = await this.api.post("/credit-packs/check-discount-code", {
      discount_code: discountCode,
    });
    return response.data;
  }

  async fetchWebhooks() {
    const response = await this.api.get("/accounts/webhooks");
    return response.data;
  }

  async createWebhook(payload) {
    const response = await this.api.post("/accounts/webhooks", payload);
    return response.data;
  }

  async deleteWebhook(id) {
    const response = await this.api.delete(`/accounts/webhooks/${id}`);
    return response.data;
  }

  async fetchApiKeys() {
    const response = await this.api.get("/accounts/api-keys");
    return response.data;
  }

  async createApiKey(payload) {
    const response = await this.api.post("/accounts/api-keys", payload);
    return response.data;
  }

  async changeApiKey(id, payload) {
    const response = await this.api.patch(`/accounts/api-keys/${id}`, payload);
    return response.data;
  }

  async deleteApiKey(id) {
    const response = await this.api.delete(`/accounts/api-keys/${id}`);
    return response.data;
  }

  async sendMessage(payload) {
    const response = await this.api.post(`/contact`, payload);
    return response.data;
  }

  async getKlaviyoList(searchText) {
    let url = "/klaviyo-lists";
    if (searchText) {
      url = url + `?search=${searchText}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async getKlaviyoListMembers(payload) {
    let url = `/klaviyo-lists/${payload.id}`;
    if (payload.marker) {
      url = url + `?marker=${payload.marker}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async getGRListMembers(payload) {
    let url = `/getresponse-lists/${payload.id}`;
    if (payload.current_page) {
      url = url + `?page=${payload.current_page}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

  async getGRList(searchText) {
    let url = "/getresponse-lists";
    if (searchText) {
      url = url + `?search=${searchText}`;
    }
    const response = await this.api.get(url);
    return response.data;
  }

}

export default AlfredService;
